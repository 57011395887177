var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Un9z9F1txuBKMcIucWXey"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://739df563b8a04304bde9ceafa64b6216@o1314878.ingest.sentry.io/6594130',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
} else {
  console.debug("Suppressing sentry in", process.env.NODE_ENV);
}
